import { useState } from "react";

import PenIcon from "@/assets/pen.svg?react";
import TrashIcon from "@/assets/trash.svg?react";

interface AdminToolCardProps {
	name: string;
	image: string;
	onEdit: () => void;
	onRemove: () => void;
}

export function AdminToolCard({
	name,
	image,
	onEdit,
	onRemove,
}: AdminToolCardProps) {
	const [isPenHovered, setIsPenHovered] = useState(false);
	const [isTrashHovered, setIsTrashHovered] = useState(false);

	return (
		<div className="flex items-center justify-between gap-4 rounded-[8px] bg-neutral-1100 bg-opacity-[0.03] p-4">
			<div className="flex size-14 items-center justify-center">
				<img src={image} alt={name} className="size-14 rounded-[10px]" />
			</div>

			<div className="flex flex-1 space-x-4">
				<div className="flex w-full flex-col gap-2">
					<div className="flex flex-col gap-[2px]">
						<span className="font-inter text-P5 font-normal leading-160 text-neutral-500">
							Nome
						</span>
						<p className="line-clamp-1 font-inter text-P5 font-medium leading-160 text-neutral-1100">
							{name}
						</p>
					</div>
				</div>
			</div>

			<div className="flex items-center gap-1">
				<button
					className={`flex items-center justify-center rounded-[6px] p-3
						${isPenHovered ? "bg-neutral-200 text-neutral-1100" : "text-neutral-600"}
						`}
					onClick={onEdit}
					onMouseEnter={() => setIsPenHovered(true)}
					onMouseLeave={() => setIsPenHovered(false)}
				>
					<PenIcon />
				</button>

				<button
					className={`flex items-center justify-center rounded-[6px] p-3
						${isTrashHovered ? "bg-neutral-200 text-neutral-1100" : "text-neutral-600"}
						`}
					onClick={onRemove}
					onMouseEnter={() => setIsTrashHovered(true)}
					onMouseLeave={() => setIsTrashHovered(false)}
				>
					<TrashIcon />
				</button>
			</div>
		</div>
	);
}
