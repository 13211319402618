import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";

import { deleteBanner } from "@/api/admin/banners/delete";
import { type Banner, fetchAdminBanners } from "@/api/admin/banners/list";
import { updateAdminBanner } from "@/api/admin/banners/update";
import MathPlus from "@/assets/math-plus.svg?react";
import SearchIcon from "@/assets/search.svg?react";
import { AdminBannerCard } from "@/components/admin-banner-card";
import { AlertModal } from "@/components/alert-modal";
import { DeleteModal } from "@/components/delete-modal";
import { useNavigationStore } from "@/store/navigation-store";

import { AdminBannersCreate } from "./create";

export function AdminBanner() {
	const [isLoading, setIsLoading] = useState(true);
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [successModal, setSuccessModal] = useState(false);
	const [banners, setBanners] = useState<Banner[]>([]);
	const [editingBanner, seteditingBanner] = useState<Banner | null>(null);
	const [showDeleteLessonModal, setShowDeleteLessonModal] = useState(false);
	const [showDeleteLessonSuccessModal, setShowDeleteLessonSuccessModal] =
		useState(false);
	const [modalLimitBanner, setModalLimitBanner] = useState(false);
	const [successActiveBanner, setSuccessActiveBanner] = useState(false);
	const [showConfirmDesactiveBanner, setShowConfirmDesactiveBanner] =
		useState(false);
	const [
		showConfirmDesactiveBannerSuccess,
		setShowConfirmDesactiveBannerSuccess,
	] = useState(false);

	async function fetchBanners() {
		setIsLoading(true);
		const response = await fetchAdminBanners();

		if (response.status === "success") {
			setBanners(response.data);
		}
		setIsLoading(false);
	}

	async function handleDelete(banner: Banner) {
		seteditingBanner(banner);
		setShowDeleteLessonModal(true);
	}

	useEffect(() => {
		fetchBanners();
	}, []);

	const handleEdit = (banner: Banner) => {
		seteditingBanner(banner);
		setModalIsOpen(true);
	};

	const handleCloseModal = () => {
		setModalIsOpen(false);
		seteditingBanner(null);
	};

	const handleCloseAfterAction = async () => {
		await fetchBanners();
		setModalIsOpen(false);
		setSuccessModal(true);
	};

	const toggleBannerStatus = async (banner: Banner) => {
		const activeBanners = banners.filter((b) => b.actived).length;
		if (!banner.actived && activeBanners >= 3) {
			setModalLimitBanner(true);
			return;
		}

		const updatedStatus = banner.actived ? 0 : 1;

		const formData = new FormData();

		formData.append("actived", updatedStatus.toString());
		formData.append("name", banner.name);
		formData.append("title", banner.title);
		formData.append("sub_title", banner.sub_title);
		formData.append("button_text", banner.button_text);
		formData.append("button_url", banner.button_url);
		formData.append("button_color", banner.button_color);

		const response = await updateAdminBanner(formData, banner.id);
		if (response.status === "success") {
			setBanners((prevBanners) =>
				prevBanners.map((prevBanner) =>
					prevBanner.id === banner.id
						? { ...prevBanner, actived: !prevBanner.actived }
						: prevBanner,
				),
			);

			if (updatedStatus === 1) {
				setSuccessActiveBanner(true);
			}

			if (updatedStatus === 0) {
				setShowConfirmDesactiveBannerSuccess(true);
			}
		}
	};

	const [setPaths] = useNavigationStore((state) => [state.setPaths]);

	useEffect(() => {
		const paths = [
			{
				name: "Configurações",
				path: "/admin",
			},
			{
				name: `Banner`,
				path: `/admin/banner`,
			},
		];

		setPaths(paths);
	}, []);

	return (
		<>
			<Helmet title="Banner" />

			<div className="flex h-full flex-col gap-12">
				<header
					className={`flex items-center justify-between border-b border-solid border-neutral-100
            ${banners.length > 0 ? "!border-none" : "!pb-12"}
          `}
				>
					<h2 className="font-inter text-P2 font-medium leading-140 text-neutral-1100">
						Todos os banners
					</h2>

					<div className="flex items-center justify-center gap-4">
						<button
							className="flex h-10 items-center justify-center gap-2 rounded-[6px] bg-primary-600 px-6 text-neutral-1100 transition-all duration-300 hover:bg-primary-700"
							onClick={() => {
								seteditingBanner(null);
								setModalIsOpen(true);
							}}
						>
							<MathPlus />

							<p className="font-inter text-P6 font-semibold leading-160">
								Adicionar
							</p>
						</button>
					</div>
				</header>

				{isLoading ? (
					<div className="flex flex-col gap-4">
						<AdminBannerCardSkeleton />
						<AdminBannerCardSkeleton />
						<AdminBannerCardSkeleton />
					</div>
				) : !banners.length ? (
					<div className="flex flex-col items-center justify-center gap-6 px-10 py-36">
						<span className="flex items-center justify-center rounded-[14px] border border-neutral-200 bg-primary-600 bg-opacity-[0.04] p-5 text-primary-600">
							<SearchIcon />
						</span>

						<div className="flex flex-col gap-2">
							<h3 className="text-center font-inter text-P2 font-medium leading-140 text-neutral-1100">
								No momento você não possui nenhum banner adicionado
							</h3>

							<p className="text-center font-inter text-P3 font-normal leading-160 text-neutral-500">
								Adicione para visualizar e gerenciar.
							</p>
						</div>
					</div>
				) : (
					<div className="flex flex-col gap-4">
						{banners.map((banner) => (
							<AdminBannerCard
								id={banner.id}
								key={banner.id}
								image={banner.image}
								title={banner.name}
								isActive={banner.actived}
								onChangeStatus={() => {
									if (banner.actived) {
										seteditingBanner(banner);
										setShowConfirmDesactiveBanner(true);

										return;
									}

									toggleBannerStatus(banner);
								}}
								onEdit={() => handleEdit(banner)}
								onDelete={() => handleDelete(banner)}
							/>
						))}
					</div>
				)}
			</div>

			{modalIsOpen && (
				<AdminBannersCreate
					closeModal={handleCloseModal}
					closeAfterCreate={handleCloseAfterAction}
					editingBanner={
						editingBanner
							? {
									id: editingBanner.id,
									name: editingBanner.name,
									actived: editingBanner.actived ? true : false,
									banner_subtitle: editingBanner.sub_title,
									banner_title: editingBanner.title,
									image: editingBanner.image,
									button_link: editingBanner.button_url,
									button_text: editingBanner.button_text,
									color: editingBanner.button_color,
								}
							: null
					}
				/>
			)}

			{successModal && (
				<AlertModal
					title={
						editingBanner
							? "Alterações salvas com sucesso!"
							: "Banner adicionado com sucesso!"
					}
					icon="done.svg"
					confirmText="Concluir"
					hasCancel={false}
					confirmAction={() => {
						setSuccessModal(false);
						seteditingBanner(null);
					}}
					cancelAction={() => {
						setSuccessModal(false);
						seteditingBanner(null);
					}}
					closeModal={() => {
						seteditingBanner(null);
						setSuccessModal(false);
					}}
				/>
			)}

			{showDeleteLessonModal && (
				<DeleteModal
					title="Deseja realmente excluir esse banner?"
					description="Essa ação não poderá ser desfeita."
					icon="warning.svg"
					confirmText="Excluir banner"
					isLoading={isLoading}
					cancelAction={() => {
						setShowDeleteLessonModal(false);
					}}
					confirmAction={async () => {
						setIsLoading(true);
						const response = await deleteBanner(editingBanner!.id);

						if (response.status === "success") {
							setIsLoading(false);
							setShowDeleteLessonModal(false);
							setShowDeleteLessonSuccessModal(true);
							await fetchBanners();
						}
					}}
					closeModal={() => {
						setShowDeleteLessonModal(false);
					}}
				/>
			)}

			{showDeleteLessonSuccessModal && (
				<AlertModal
					title="Banner excluído com sucesso!"
					icon="done.svg"
					confirmText="Concluir"
					hasCancel={false}
					confirmAction={() => {
						setShowDeleteLessonSuccessModal(false);
					}}
					closeModal={() => {
						setShowDeleteLessonSuccessModal(false);
					}}
				/>
			)}

			{modalLimitBanner && (
				<AlertModal
					title="Você atingiu o limite de banners ativos!"
					description="O máximo permitido é 3 banners ativos. Desative um deles para ativar este."
					icon="warning-2.svg"
					confirmText="Entendi"
					hasCancel={false}
					confirmAction={() => {
						setModalLimitBanner(false);
					}}
					closeModal={() => {
						setModalLimitBanner(false);
					}}
				/>
			)}

			{successActiveBanner && (
				<AlertModal
					title="Banner ativado com sucesso!"
					icon="done.svg"
					confirmText="Concluir"
					hasCancel={false}
					confirmAction={() => {
						setSuccessActiveBanner(false);
					}}
					closeModal={() => {
						setSuccessActiveBanner(false);
					}}
				/>
			)}

			{showConfirmDesactiveBanner && (
				<AlertModal
					title="Tem certeza que deseja desativar este banner?"
					description="Ele não ficará visível para os afiliados até que você o reative."
					icon="warning-2.svg"
					confirmText="Desativar banner"
					isLoading={isLoading}
					cancelAction={() => {
						setShowConfirmDesactiveBanner(false);
					}}
					confirmAction={async () => {
						setIsLoading(true);
						await toggleBannerStatus(editingBanner!);
						setShowConfirmDesactiveBanner(false);
						setIsLoading(false);
					}}
					closeModal={() => {
						setShowConfirmDesactiveBanner(false);
					}}
				/>
			)}

			{showConfirmDesactiveBannerSuccess && (
				<AlertModal
					title="Banner desativado com sucesso!"
					icon="done.svg"
					confirmText="Concluir"
					hasCancel={false}
					confirmAction={() => {
						setShowConfirmDesactiveBannerSuccess(false);
					}}
					closeModal={() => {
						setShowConfirmDesactiveBannerSuccess(false);
					}}
				/>
			)}
		</>
	);
}

function AdminBannerCardSkeleton() {
	return (
		<div className="flex items-center justify-between gap-4 rounded-[8px] bg-neutral-1100 bg-opacity-[0.03] p-4">
			<div className="flex size-14 items-center justify-center">
				<div className="size-14 animate-pulse rounded-[10px] bg-neutral-200" />
			</div>

			<div className="flex flex-1 space-x-4">
				<div className="flex w-full flex-col gap-2">
					<div className="flex flex-col gap-[2px]">
						<div className="h-4 w-16 animate-pulse rounded bg-neutral-200" />
						<div className="h-5 w-40 animate-pulse rounded bg-neutral-200" />
					</div>
				</div>
			</div>
		</div>
	);
}
