import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { createAdminTool } from "@/api/admin/tools/create";
import { updateAdminTool } from "@/api/admin/tools/update";
import { FileCard } from "@/components/file-card";
import { FileUploader } from "@/components/file-uploader";
import { Modal } from "@/components/modal";
import { TextareaInput } from "@/components/textarea-input";
import { FloatingLabelInput } from "@/components/ui/float-input";
import { Form } from "@/components/ui/form";

interface AdminToolsCreateProps {
	closeModal: () => void;
	closeAfterCreate: () => void;
	editingTool?: {
		id: string;
		name: string;
		description: string;
		image: string;
		access_link: string;
		more_link: string;
	} | null;
}

export function AdminToolsCreate({
	closeModal,
	closeAfterCreate,
	editingTool,
}: AdminToolsCreateProps) {
	const params = z.object({
		name: z.string().min(1, "O nome é obrigatório"),
		description: z.string().min(1, "A descrição é obrigatória"),
		image: z.union([z.instanceof(File), z.string()]).refine(
			(image) => {
				if (image instanceof File) {
					return image.size <= 4 * 1024 * 1024;
				}
				return true;
			},
			{
				message: "A imagem deve ter no máximo 4MB.",
			},
		),
		access_link: z.string().url("URL inválida"),
		more_link: z.string().url("URL inválida"),
	});

	type Params = z.infer<typeof params>;

	const form = useForm<Params>({
		resolver: zodResolver(params),
		defaultValues: editingTool || {},
	});

	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (editingTool) {
			form.reset(editingTool);
		}
	}, [editingTool, form]);

	async function handleSubmit(data: {
		id?: string;
		name: string;
		description: string;
		image: File | string;
		access_link: string;
		more_link: string;
	}) {
		try {
			setIsLoading(true);
			const formData = new FormData();

			formData.append("name", data.name);
			formData.append("description", data.description);
			formData.append("url_access", data.access_link);
			formData.append("url_video", data.more_link);

			if (data.image instanceof File) {
				formData.append("image", data.image);
			}

			if (editingTool && data.image === "") {
				form.setError("image", {
					type: "manual",
					message: "A imagem é obrigatória",
				});

				setIsLoading(false);

				return;
			}

			let response;
			if (editingTool) {
				response = await updateAdminTool(formData, editingTool.id);
			} else {
				response = await createAdminTool(formData);
			}

			if (response?.status === "success") {
				closeAfterCreate();
				return;
			}

			if (response?.status === "error") {
				const { errors } = response;

				for (const key in errors) {
					form.setError(key as keyof Params, {
						type: "manual",
						message: errors[key],
					});
				}
			}
		} catch (error) {
			console.error("Erro ao enviar formulário:", error);
		} finally {
			setIsLoading(false);
		}
	}

	return (
		<Modal
			title={editingTool ? "Editar ferramenta" : "Adicionar ferramenta"}
			closeModal={closeModal}
			cancelAction={closeModal}
			confirmAction={form.handleSubmit(handleSubmit)}
			isLoading={isLoading}
			confirmText={editingTool ? "Salvar" : "Adicionar"}
		>
			{!isLoading && (
				<Form {...form}>
					<div className="flex flex-col gap-8">
						<div className="flex w-full flex-col items-center gap-6">
							<div className="flex w-full flex-col gap-2">
								<FloatingLabelInput
									id="name"
									type="text"
									label="Nome da ferramenta"
									hasError={!!form.formState.errors.name}
									{...form.register("name")}
								/>

								{form.formState.errors.name && (
									<p className="font-inter text-P6 font-normal leading-160 text-red-600">
										{form.formState.errors.name.message}
									</p>
								)}
							</div>
						</div>

						<div className="flex w-full flex-col gap-2">
							<TextareaInput
								setValue={(e) => form.setValue("description", e)}
								value={form.watch("description") || ""}
								placeholder="Sobre a ferramenta"
								showButtons={false}
								height="150px"
								maxLength={180}
								heightIsFixed
							/>

							{form.formState.errors.description && (
								<p className="font-inter text-P6 font-normal leading-160 text-red-600">
									{form.formState.errors.description.message}
								</p>
							)}
						</div>

						<div className="flex flex-col gap-8 rounded-[8px] border border-solid border-neutral-200 p-6">
							<div className="flex flex-col gap-2">
								<p className="font-inter text-P3 font-medium leading-160 text-neutral-1100">
									Logomarca da ferramenta
								</p>
								<span className="font-inter text-P5 font-normal leading-160 text-neutral-500">
									Essa será a imagem que estará na visualização do card da
									ferramenta.
								</span>
							</div>
							<div className="flex w-full flex-col gap-2">
								{form.watch("image") ? (
									typeof form.watch("image") === "string" ? (
										<FileCard
											url={form.watch("image") as string}
											onRemove={() => form.setValue("image", "")}
										/>
									) : (
										<FileCard
											file={form.watch("image") as File}
											onRemove={() => form.setValue("image", "")}
										/>
									)
								) : (
									<FileUploader
										maxFiles={1}
										maxSize={10 * 1024 * 1024}
										accept={{ "image/*": [".png", ".jpeg", ".jpg"] }}
										onUpload={async (e) => {
											form.setValue("image", e[0]);
										}}
										disabled={false}
									/>
								)}

								{form.formState.errors.image && (
									<p className="font-inter text-P6 font-normal leading-160 text-red-600">
										{form.formState.errors.image.message}
									</p>
								)}
							</div>
						</div>

						<div className="flex flex-col gap-8 rounded-[8px] border border-solid border-neutral-200 p-6">
							<p className="font-inter text-P3 font-medium leading-160 text-neutral-1100">
								Links dos botões
							</p>

							<div className="flex flex-col gap-4">
								<div className="flex flex-col gap-8 rounded-[8px] bg-neutral-1100 bg-opacity-[0.03] p-6">
									<div className="flex flex-col gap-2">
										<p className="font-inter text-P3 font-medium leading-160 text-neutral-1100">
											Botão de acesso
										</p>

										<span className="font-inter text-P5 font-normal leading-160 text-neutral-500">
											Adicione o link para a página de destino da ferramenta.
										</span>
									</div>

									<div className="flex w-full flex-col gap-2">
										<FloatingLabelInput
											id="access_link"
											type="text"
											label="Link da página"
											bgColor="bg-transparent"
											hasError={!!form.formState.errors.access_link}
											{...form.register("access_link")}
										/>

										{form.formState.errors.access_link && (
											<p className="font-inter text-P6 font-normal leading-160 text-red-600">
												{form.formState.errors.access_link.message}
											</p>
										)}
									</div>
								</div>
							</div>

							<div className="flex flex-col gap-4">
								<div className="flex flex-col gap-8 rounded-[8px] bg-neutral-1100 bg-opacity-[0.03] p-6">
									<div className="flex flex-col gap-2">
										<p className="font-inter text-P3 font-medium leading-160 text-neutral-1100">
											Botão de saiba mais
										</p>

										<span className="font-inter text-P5 font-normal leading-160 text-neutral-500">
											Adicione o link do vídeo explicativo da ferramenta.
										</span>
									</div>

									<div className="flex w-full flex-col gap-2">
										<FloatingLabelInput
											id="more_link"
											type="text"
											label="Link do vídeo"
											bgColor="bg-transparent"
											hasError={!!form.formState.errors.more_link}
											{...form.register("more_link")}
										/>

										{form.formState.errors.more_link && (
											<p className="font-inter text-P6 font-normal leading-160 text-red-600">
												{form.formState.errors.more_link.message}
											</p>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</Form>
			)}
		</Modal>
	);
}
