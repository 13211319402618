import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { cn } from "@/lib/utils";

interface ColorOption {
	value: string;
	label: string;
	className: string;
}

const colorOptions: ColorOption[] = [
	{ value: "#DF32B3", label: "Rosa", className: "bg-[#DF32B3]" },
	{ value: "#932CFA", label: "Roxo", className: "bg-[#932CFA]" },
	{ value: "#1D88F3", label: "Azul", className: "bg-[#1D88F3]" },
	{ value: "#058D4C", label: "Verde", className: "bg-[#058D4C]" },
	{ value: "#EAB506", label: "Amarelo", className: "bg-[#EAB506]" },
	{ value: "#FF9900", label: "Laranja", className: "bg-[#FF9900]" },
	{ value: "#BB0A0A", label: "Vermelho", className: "bg-[#BB0A0A]" },
	{ value: "#4E5656", label: "Cinza", className: "bg-[#4E5656]" },
];

interface ColorPickerProps {
	onColorChange?: (color: string) => void;
	selectedColor?: string;
}

export function ColorPicker({
	onColorChange,
	selectedColor,
}: ColorPickerProps) {
	const handleColorChange = (value: string) => {
		onColorChange?.(value);
	};

	return (
		<RadioGroup
			defaultValue={selectedColor}
			onValueChange={handleColorChange}
			className="flex w-full justify-center gap-4"
		>
			{colorOptions.map((color) => (
				<div key={color.value} className="relative">
					<RadioGroupItem
						value={color.value}
						id={color.value}
						className="peer sr-only"
					/>
					<Label
						htmlFor={color.value}
						className={cn(
							"block h-10 w-11 cursor-pointer rounded-lg transition-all",
							"hover:scale-105 hover:ring-0 hover:ring-offset-2",
							"peer-focus-visible:ring-0 peer-focus-visible:ring-offset-2",
							"peer-data-[state=checked]:ring-0 peer-data-[state=checked]:ring-offset-2",
							color.className,
						)}
					>
						<span className="sr-only">{color.label}</span>
					</Label>
				</div>
			))}
		</RadioGroup>
	);
}
