import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { createAdminBanner } from "@/api/admin/banners/create";
import { updateAdminBanner } from "@/api/admin/banners/update";
import { ColorPicker } from "@/components/color-picker";
import { FileCard } from "@/components/file-card";
import { FileUploader } from "@/components/file-uploader";
import { Modal } from "@/components/modal";
import { TextareaInput } from "@/components/textarea-input";
import { FloatingLabelInput } from "@/components/ui/float-input";
import { Form } from "@/components/ui/form";

interface AdminBannersCreateProps {
	closeModal: () => void;
	closeAfterCreate: () => void;
	editingBanner?: {
		id: string;
		name: string;
		actived: boolean;
		banner_title: string;
		banner_subtitle: string;
		color: string;
		image: string;
		button_text: string;
		button_link: string;
	} | null;
}

export function AdminBannersCreate({
	closeModal,
	closeAfterCreate,
	editingBanner,
}: AdminBannersCreateProps) {
	const params = z.object({
		name: z.string().min(1, "O nome é obrigatório"),
		actived: z.boolean().optional(),
		banner_title: z
			.string()
			.min(1, "O título do banner é obrigatório")
			.max(70, "O título do banner deve ter no máximo 70 caracteres"),
		banner_subtitle: z
			.string()
			.min(1, "O subtítulo do banner é obrigatório")
			.max(160, "O subtítulo do banner deve ter no máximo 160 caracteres"),
		color: z.string().min(1, "A cor é obrigatória"),
		image: z
			.union([
				z.instanceof(File, {
					message: "A imagem é obrigatória",
				}),
				z.string(),
			])
			.refine(
				(image) => {
					if (image instanceof File) {
						return image.size <= 4 * 1024 * 1024;
					}
					return true;
				},
				{
					message: "A imagem deve ter no máximo 4MB.",
				},
			),
		button_text: z
			.string()
			.min(1, "O texto do botão é obrigatório")
			.max(25, "O texto do botão deve ter no máximo 25 caracteres"),
		button_link: z.string().url("URL inválida"),
	});

	type Params = z.infer<typeof params>;

	const form = useForm<Params>({
		resolver: zodResolver(params),
		defaultValues: editingBanner || {},
		mode: "all",
	});

	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (editingBanner) {
			form.reset(editingBanner);
		}
	}, [editingBanner, form]);

	async function handleSubmit(data: {
		id?: string;
		actived?: boolean;
		name: string;
		banner_title: string;
		banner_subtitle: string;
		color: string;
		image: string | File;
		button_text: string;
		button_link: string;
	}) {
		try {
			setIsLoading(true);
			const formData = new FormData();

			formData.append("name", data.name);
			formData.append("actived", data.actived ? "1" : "0");
			formData.append("title", data.banner_title);
			formData.append("sub_title", data.banner_subtitle);
			formData.append("button_text", data.button_text);
			formData.append("button_url", data.button_link);
			formData.append("button_color", data.color);

			if (data.image instanceof File) {
				formData.append("image", data.image);
			}

			if (editingBanner && data.image === "") {
				form.setError("image", {
					type: "manual",
					message: "A imagem é obrigatória",
				});

				setIsLoading(false);

				return;
			}

			let response;
			if (editingBanner) {
				response = await updateAdminBanner(formData, editingBanner.id);
			} else {
				response = await createAdminBanner(formData);
			}

			if (response?.status === "success") {
				closeAfterCreate();
				return;
			}

			if (response?.status === "error") {
				const { errors } = response;

				for (const key in errors) {
					form.setError(key as keyof Params, {
						type: "manual",
						message: errors[key],
					});
				}
			}
		} catch (error) {
			console.error("Erro ao enviar formulário:", error);
		} finally {
			setIsLoading(false);
		}
	}

	return (
		<Modal
			title={editingBanner ? "Editar banner" : "Adicionar banner"}
			closeModal={closeModal}
			cancelAction={closeModal}
			confirmAction={form.handleSubmit(handleSubmit)}
			isLoading={isLoading}
			confirmText={editingBanner ? "Salvar" : "Adicionar"}
		>
			{!isLoading && (
				<Form {...form}>
					<div className="flex flex-col gap-8">
						<div className="flex w-full flex-col items-center gap-6">
							<div className="flex w-full flex-col gap-2">
								<FloatingLabelInput
									id="name"
									type="text"
									label="Nome do banner"
									hasError={!!form.formState.errors.name}
									{...form.register("name")}
								/>

								{form.formState.errors.name && (
									<p className="font-inter text-P6 font-normal leading-160 text-red-600">
										{form.formState.errors.name.message}
									</p>
								)}
							</div>
						</div>

						<div className="flex flex-col gap-8 rounded-[8px] border border-solid border-neutral-200 p-6">
							<div className="flex flex-col gap-2">
								<p className="font-inter text-P3 font-medium leading-160 text-neutral-1100">
									Personalização do texto
								</p>
								<span className="font-inter text-P5 font-normal leading-160 text-neutral-500">
									Insira o título e o subtítulo que irá aparecer no seu banner
								</span>
							</div>

							<div className="flex w-full flex-col gap-2">
								<FloatingLabelInput
									id="banner_title"
									type="text"
									label="Título do banner"
									hasError={!!form.formState.errors.banner_title}
									{...form.register("banner_title")}
								/>

								<p className="font-inter text-P6 font-normal leading-160 text-neutral-500">
									{form.watch("banner_title")?.length
										? form.watch("banner_title").length
										: 0}{" "}
									de 70 caracteres.
								</p>

								{form.formState.errors.banner_title && (
									<p className="font-inter text-P6 font-normal leading-160 text-red-600">
										{form.formState.errors.banner_title.message}
									</p>
								)}
							</div>

							<div className="flex w-full flex-col gap-2">
								<TextareaInput
									setValue={(e) => form.setValue("banner_subtitle", e)}
									value={form.watch("banner_subtitle") || ""}
									placeholder="Subtítulo do banner"
									showButtons={false}
									height="150px"
									maxLength={160}
									heightIsFixed
									alwaysShowCounter
								/>

								{form.formState.errors.banner_subtitle && (
									<p className="font-inter text-P6 font-normal leading-160 text-red-600">
										{form.formState.errors.banner_subtitle.message}
									</p>
								)}
							</div>
						</div>

						<div className="flex flex-col gap-8 rounded-[8px] border border-solid border-neutral-200 p-6">
							<p className="font-inter text-P3 font-medium leading-160 text-neutral-1100">
								Personalização botão
							</p>

							<div className="flex flex-col gap-4">
								<div className="flex flex-col gap-8 rounded-[8px] bg-neutral-1100 bg-opacity-[0.03] p-6">
									<div className="flex flex-col gap-2">
										<p className="font-inter text-P3 font-medium leading-160 text-neutral-1100">
											Texto e Link
										</p>

										<span className="font-inter text-P5 font-normal leading-160 text-neutral-500">
											Adicione o texto de sua preferência e o link de
											direcionamento.
										</span>
									</div>

									<div className="flex w-full items-start gap-6">
										<div className="flex w-full flex-col gap-2">
											<FloatingLabelInput
												id="button_text"
												type="text"
												label="Texto do botão"
												bgColor="bg-transparent"
												hasError={!!form.formState.errors.button_text}
												{...form.register("button_text")}
											/>

											<p className="font-inter text-P6 font-normal leading-160 text-neutral-500">
												{form.watch("button_text")?.length
													? form.watch("button_text").length
													: 0}{" "}
												de 25 caracteres.
											</p>

											{form.formState.errors.button_text && (
												<p className="font-inter text-P6 font-normal leading-160 text-red-600">
													{form.formState.errors.button_text.message}
												</p>
											)}
										</div>

										<div className="flex w-full flex-col gap-2">
											<FloatingLabelInput
												id="button_link"
												type="text"
												label="Link da página"
												bgColor="bg-transparent"
												hasError={!!form.formState.errors.button_link}
												{...form.register("button_link")}
											/>

											{form.formState.errors.button_link && (
												<p className="font-inter text-P6 font-normal leading-160 text-red-600">
													{form.formState.errors.button_link.message}
												</p>
											)}
										</div>
									</div>
								</div>

								<div className="flex flex-col gap-8 rounded-[8px] bg-neutral-1100 bg-opacity-[0.03] p-6">
									<div className="flex flex-col gap-2">
										<p className="font-inter text-P3 font-medium leading-160 text-neutral-1100">
											Cor
										</p>

										<span className="font-inter text-P5 font-normal leading-160 text-neutral-500">
											Selecione uma cor da sua preferência para o botão.
										</span>
									</div>

									<div className="flex w-full items-center gap-6">
										<ColorPicker
											selectedColor={form.watch("color")}
											onColorChange={(color) => {
												form.setValue("color", color);
											}}
										/>
									</div>
								</div>
							</div>
						</div>

						<div className="flex flex-col gap-8 rounded-[8px] border border-solid border-neutral-200 p-6">
							<div className="flex flex-col gap-2">
								<p className="font-inter text-P3 font-medium leading-160 text-neutral-1100">
									Imagem do banner
								</p>
								<span className="font-inter text-P5 font-normal leading-160 text-neutral-500">
									Essa será a imagem que estará na visualização do banner.
								</span>
							</div>
							<div className="flex w-full flex-col gap-2">
								{form.watch("image") ? (
									typeof form.watch("image") === "string" ? (
										<FileCard
											url={form.watch("image") as string}
											onRemove={() => form.setValue("image", "")}
										/>
									) : (
										<FileCard
											file={form.watch("image") as File}
											onRemove={() => form.setValue("image", "")}
										/>
									)
								) : (
									<FileUploader
										maxFiles={1}
										maxSize={10 * 1024 * 1024}
										accept={{ "image/*": [".png"] }}
										onUpload={async (e) => {
											form.clearErrors("image");

											form.setValue("image", e[0]);
										}}
										disabled={false}
										customMessage="Formato .png de até 10mb, tamanho recomendado de 1920x1000"
									/>
								)}

								{form.formState.errors.image && (
									<p className="font-inter text-P6 font-normal leading-160 text-red-600">
										{form.formState.errors.image.message}
									</p>
								)}
							</div>
						</div>
					</div>
				</Form>
			)}
		</Modal>
	);
}
