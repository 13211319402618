import { Check } from "lucide-react";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";

import { fetchAdminTools, type Tool } from "@/api/admin/tools/list";
import SearchIcon from "@/assets/search.svg?react";
import { ToolCard } from "@/components/tool-card";
import { Skeleton } from "@/components/ui/skeleton";
import { useNavigationStore } from "@/store/navigation-store";

const advantages = [
	"Maior produtividade no dia a dia",
	"Planos de acordo com sua necessidade",
	"Desconto exclusivo para afiliado",
	"Suporte especializado",
];

export function Tools() {
	const [tools, setTools] = useState<Tool[]>([]);
	const [isLoading, setIsLoading] = useState(true);

	async function fetchTools() {
		setIsLoading(true);
		const response = await fetchAdminTools();

		if (response.status === "success") {
			setTools(response.data);
		}
		setIsLoading(false);
	}

	const [setPaths] = useNavigationStore((state) => [state.setPaths]);

	useEffect(() => {
		const paths = [
			{
				name: "Ferramentas",
				path: "/tools",
			},
		];

		setPaths(paths);

		fetchTools();
	}, []);

	return (
		<>
			<Helmet title="Ferramentas" />

			<div className="relative flex h-full w-full flex-col gap-12 overflow-auto md:flex-row md:gap-24">
				<section className="sticky top-2 h-full w-full md:max-w-[352px]">
					<div className="flex flex-col gap-8">
						<div className="flex flex-col gap-2">
							<h3 className="font-inter text-P3 font-medium leading-160 text-neutral-1100">
								Descubra como nossas ferramentas podem ajudar suas vendas!
							</h3>

							<p className="font-inter text-P4 font-normal leading-160 text-neutral-500">
								Potencialize sua operação com as ferramentas certas. Aumente
								suas vendas, agilize o atendimento, simplifique a gestão de
								conteúdos e conquiste resultados incríveis!
							</p>
						</div>

						<div className="flex flex-col gap-4">
							<p className="font-inter text-P4 font-medium leading-160 text-neutral-1100">
								Algumas vantagens:
							</p>

							<div className="flex flex-col gap-3">
								{advantages.map((advantage) => (
									<div
										key={advantage}
										className="flex items-center gap-3 rounded-[10px] bg-neutral-1100 bg-opacity-[0.03] px-3 py-2"
									>
										<div className="flex h-5 w-5 items-center justify-center rounded-full bg-primary-600">
											<Check className="h-3 w-3 text-white" />
										</div>

										<span className="font-inter text-P5 font-normal leading-160 text-neutral-800">
											{advantage}
										</span>
									</div>
								))}
							</div>
						</div>
					</div>
				</section>

				<section className="flex-grow">
					<div className="flex flex-col gap-6">
						{isLoading ? (
							Array.from({ length: 3 }).map((_, index) => (
								<SkeletonToolCard key={index} />
							))
						) : !tools.length ? (
							<div className="flex flex-col items-center justify-center gap-6 px-10 py-36">
								<span className="flex items-center justify-center rounded-[14px] border border-neutral-200 bg-primary-600 bg-opacity-[0.04] p-5 text-primary-600">
									<SearchIcon />
								</span>

								<div className="flex flex-col gap-2">
									<h3 className="text-center font-inter text-P2 font-medium leading-140 text-neutral-1100">
										No momento não há ferramentas disponíveis
									</h3>
								</div>
							</div>
						) : (
							tools.map((tool) => (
								<ToolCard
									key={tool.id}
									access_url={tool.url_access}
									description={tool.description}
									image={tool.image}
									name={tool.name}
									more_url={tool.url_video}
								/>
							))
						)}
					</div>
				</section>
			</div>
		</>
	);
}

function SkeletonToolCard() {
	return (
		<div className="flex items-start justify-start gap-6 rounded-xl bg-neutral-100 p-6">
			<Skeleton className="size-14 rounded-[10px]" />
			<div className="flex w-full gap-6">
				<div className="flex w-full flex-col gap-2">
					<Skeleton className="h-6 w-3/4" />
					<Skeleton className="h-[2.8em] w-full" />
				</div>
				<div className="flex w-full max-w-[135px] flex-col justify-center gap-3">
					<Skeleton className="!h-10 font-inter !text-P6 font-semibold leading-160 text-neutral-1100" />
					<Skeleton className="!h-10 font-inter !text-P6 font-semibold leading-160 text-neutral-1100" />
				</div>
			</div>
		</div>
	);
}
