import MoreAltIcon from "@/assets/more-alt.svg?react";
import PenIcon from "@/assets/pen.svg?react";
import TrashIcon from "@/assets/trash.svg?react";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

import { Switch } from "./ui/switch";

interface AdminBannerCardProps {
	id: string;
	image: string;
	title: string;
	isActive: boolean;
	onChangeStatus: (value: boolean) => void;
	onEdit: () => void;
	onDelete: () => void;
}

export function AdminBannerCard({
	id,
	image,
	title,
	isActive,
	onChangeStatus,
	onEdit,
	onDelete,
}: AdminBannerCardProps) {
	const optionsIcons = {
		trash: <TrashIcon />,
		pen: <PenIcon />,
	};

	const options:
		| {
				title: string;
				icon: keyof typeof optionsIcons;
		  }[]
		| undefined = [
		{ title: "Editar", icon: "pen" },
		{ title: "Excluir", icon: "trash" },
	];

	return (
		<div className="flex w-full items-center justify-between gap-6 rounded-[6px] bg-neutral-1100 bg-opacity-5 p-4">
			<div className="flex w-full items-center  gap-6">
				<img
					src={image || "https://via.placeholder.com/150"}
					className="h-12 max-h-12 min-h-12 w-12 min-w-12 max-w-12 rounded-[8px] object-cover"
				/>

				<div className="flex w-full flex-1 flex-col gap-2">
					<span className="font-inter text-P6 font-normal leading-160 text-neutral-600">
						Nome
					</span>

					<p className="line-clamp-1 text-ellipsis font-inter text-P5 font-medium leading-160 text-neutral-1100">
						{title}
					</p>
				</div>
			</div>

			<div className="flex items-center gap-4">
				<div className="flex items-center gap-4">
					<p className="hidden font-inter text-P6 font-medium leading-160 text-neutral-1100 md:block">
						{isActive ? "Ativado" : "Desativado"}
					</p>

					<Switch checked={isActive} onCheckedChange={onChangeStatus} />
				</div>

				<div className="h-6 w-[1px] bg-neutral-200"></div>

				<span className="relative flex size-8 cursor-pointer items-center justify-center rounded-[8px] bg-transparent p-2  text-neutral-600 transition-all duration-300 hover:rounded-[8px] hover:bg-neutral-200">
					<DropdownMenu>
						<DropdownMenuTrigger className="focus-visible:outline-none">
							<MoreAltIcon />
						</DropdownMenuTrigger>
						<DropdownMenuContent>
							{options.map((option, index) => (
								<DropdownMenuItem
									key={id + index + "option"}
									onClick={option.title === "Editar" ? onEdit : onDelete}
								>
									{optionsIcons[option.icon]}
									<p>{option.title}</p>
								</DropdownMenuItem>
							))}
						</DropdownMenuContent>
					</DropdownMenu>
				</span>
			</div>
		</div>
	);
}
