import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Outlet, useNavigate, useParams } from "react-router-dom";

import { showProduct } from "@/api/app/products/show";
import BraipLogo from "@/assets/braip.svg?react";
import MonetizzeLogo from "@/assets/monetizze.svg?react";
import ShoppingCartIcon from "@/assets/shopping-cart.svg?react";
import TelegramIcon from "@/assets/telegram.svg?react";
import TheMartLogo from "@/assets/themart.svg?react";
import { SkeletonProduct } from "@/components/skeletons/product";
import { TabNavigation } from "@/components/tab-navigation";
import { Button } from "@/components/ui/button";
import { useNavigationStore } from "@/store/navigation-store";
import { useProductStore } from "@/store/product";

export function Product() {
	const { id } = useParams<{ id: string }>();

	const [setPaths] = useNavigationStore((state) => [state.setPaths]);
	const { product, setProduct } = useProductStore();

	useEffect(() => {
		const paths = [
			{
				name: "Produtos",
				path: "/products",
			},
			{
				name: `Detalhes do produto`,
				path: `/products/${id}`,
			},
		];

		setPaths(paths);
	}, []);

	const tabs = [
		{
			title: "Sobre",
			to: `/products/${id}`,
		},
		{
			title: "Materiais",
			to: `/products/${id}/materials`,
		},
		{
			title: "Dúvidas",
			to: `/products/${id}/questions`,
		},
	];

	const navigate = useNavigate();

	async function getProductById() {
		if (!id) return navigate("/products");

		const response = await showProduct(id);

		if (response.status === "success") {
			setProduct(response.data);
		}

		if (response.status === "error") {
			navigate("/products");
		}
	}

	useEffect(() => {
		setProduct(null);
		getProductById();
	}, [id]);

	return (
		<>
			<Helmet title={product?.name} />

			{!product ? (
				<SkeletonProduct />
			) : (
				<div className="relative flex flex-col gap-8 lg:flex-row">
					<section className="flex h-fit w-full flex-col gap-8 rounded-[12px] bg-neutral-100 bg-opacity-40 lg:sticky lg:top-32 lg:max-w-[352px]">
						<div className="mx-4 mt-4">
							<img
								src={product.image}
								className="h-[180px] w-full rounded-xl object-cover lg:w-[320px]"
							/>
						</div>

						<div className="flex flex-col gap-8 px-6 pb-6">
							<div className="flex flex-col gap-2">
								<span className="font-inter text-P4 font-normal leading-160 text-neutral-500">
									{product.type.name}
								</span>

								<p className="line-clamp-1 text-ellipsis font-inter text-P1 font-semibold leading-140 text-neutral-1100">
									{product.name}
								</p>

								{product.status_sale_link && product.sale_link ? (
									<Button
										onClick={() =>
											product?.sale_link &&
											window.open(product.sale_link, "_blank")
										}
										className="mt-4 !h-12 w-full gap-2 font-inter !text-P5 font-semibold leading-160 text-neutral-1100"
									>
										<ShoppingCartIcon />
										Comprar pela metade do preço
									</Button>
								) : null}
							</div>
							<hr className="h-[2px] border-t-neutral-100" />

							<div className="flex flex-col gap-4">
								<p className="font-inter text-P5 font-medium leading-160 text-neutral-1100">
									Escolha uma plataforma para afiliar:
								</p>
								<div className="flex flex-wrap gap-3">
									{product.platforms.map((platform) => (
										<button
											key={platform.platform}
											className={`flex h-12 w-[92px] items-center justify-center rounded-[8px] bg-neutral-100 transition-all duration-300
												${platform.platform === "The Mart" && "hover:bg-[#FF6700]"}
												${platform.platform === "Braip" && "hover:bg-[#6D36FB]"}
												${platform.platform === "Monetizze" && "hover:bg-[#0500EC]"}
												`}
											onClick={() => window.open(platform.link, "_blank")}
										>
											{
												{
													"The Mart": <TheMartLogo />,
													Braip: <BraipLogo />,
													Monetizze: <MonetizzeLogo width={70} />,
												}[platform.platform]
											}
										</button>
									))}
								</div>
							</div>

							<div className="flex flex-col gap-4 rounded-[10px] border border-neutral-100 bg-neutral-100 bg-opacity-80 p-4">
								<p className="font-inter text-P5 font-medium leading-160 text-neutral-1100">
									Grupo de suporte para afiliados:
								</p>

								<button
									className="flex h-12 items-center justify-center gap-2 rounded-[8px] bg-blue-600 px-6 transition-all duration-300 hover:bg-blue-800"
									onClick={() =>
										window.open(
											product.telegram || "https://telegram.org/",
											"_blank",
										)
									}
								>
									<TelegramIcon />

									<p className="font-inter text-P5 font-semibold leading-160 text-neutral-1100">
										Entrar no grupo
									</p>
								</button>
							</div>
						</div>
					</section>

					<section className="flex w-full flex-col gap-12">
						<TabNavigation tabs={tabs} />

						<Outlet />
					</section>
				</div>
			)}
		</>
	);
}
