import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";

import { deleteTool } from "@/api/admin/tools/delete";
import { fetchAdminTools, type Tool } from "@/api/admin/tools/list";
import MathPlus from "@/assets/math-plus.svg?react";
import SearchIcon from "@/assets/search.svg?react";
import { AdminToolCard } from "@/components/admin-tool-card";
import { AlertModal } from "@/components/alert-modal";
import { DeleteModal } from "@/components/delete-modal";
import { useNavigationStore } from "@/store/navigation-store";

import { AdminToolsCreate } from "./create";

export function AdminTools() {
	const [isLoading, setIsLoading] = useState(true);
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [successModal, setSuccessModal] = useState(false);
	const [tools, setTools] = useState<Tool[]>([]);
	const [editingTool, setEditingTool] = useState<Tool | null>(null);
	const [showDeleteLessonModal, setShowDeleteLessonModal] = useState(false);
	const [showDeleteLessonSuccessModal, setShowDeleteLessonSuccessModal] =
		useState(false);

	async function fetchTools() {
		setIsLoading(true);
		const response = await fetchAdminTools();

		if (response.status === "success") {
			setTools(response.data);
		}
		setIsLoading(false);
	}

	async function handleDelete(tool: Tool) {
		setEditingTool(tool);
		setShowDeleteLessonModal(true);
	}

	useEffect(() => {
		fetchTools();
	}, []);

	const handleEdit = (tool: Tool) => {
		setEditingTool(tool);
		setModalIsOpen(true);
	};

	const handleCloseModal = () => {
		setModalIsOpen(false);
		setEditingTool(null);
	};

	const handleCloseAfterAction = async () => {
		await fetchTools();
		setModalIsOpen(false);
		setSuccessModal(true);
	};

	const [setPaths] = useNavigationStore((state) => [state.setPaths]);

	useEffect(() => {
		const paths = [
			{
				name: "Configurações",
				path: "/admin",
			},
			{
				name: `Ferramentas`,
				path: `/admin/tools`,
			},
		];

		setPaths(paths);
	}, []);

	return (
		<>
			<Helmet title="Ferramentas" />

			<div className="flex h-full flex-col gap-12">
				<header
					className={`flex items-center justify-between border-b border-solid border-neutral-100
            ${tools.length > 0 ? "!border-none" : "!pb-12"}
          `}
				>
					<h2 className="font-inter text-P2 font-medium leading-140 text-neutral-1100">
						Todos as ferramentas
					</h2>

					<div className="flex items-center justify-center gap-4">
						<button
							className="flex h-10 items-center justify-center gap-2 rounded-[6px] bg-primary-600 px-6 text-neutral-1100 transition-all duration-300 hover:bg-primary-700"
							onClick={() => {
								setEditingTool(null);
								setModalIsOpen(true);
							}}
						>
							<MathPlus />

							<p className="font-inter text-P6 font-semibold leading-160">
								Adicionar
							</p>
						</button>
					</div>
				</header>

				{isLoading ? (
					<div className="flex flex-col gap-4">
						<AdminToolCardSkeleton />
						<AdminToolCardSkeleton />
						<AdminToolCardSkeleton />
					</div>
				) : !tools.length ? (
					<div className="flex flex-col items-center justify-center gap-6 px-10 py-36">
						<span className="flex items-center justify-center rounded-[14px] border border-neutral-200 bg-primary-600 bg-opacity-[0.04] p-5 text-primary-600">
							<SearchIcon />
						</span>

						<div className="flex flex-col gap-2">
							<h3 className="text-center font-inter text-P2 font-medium leading-140 text-neutral-1100">
								No momento você não possui nenhuma ferramenta adicionada
							</h3>

							<p className="text-center font-inter text-P3 font-normal leading-160 text-neutral-500">
								Adicione para visualizar e gerenciar.
							</p>
						</div>
					</div>
				) : (
					<div className="flex flex-col gap-4">
						{tools.map((tool) => (
							<AdminToolCard
								key={tool.id}
								name={tool.name}
								image={tool.image}
								onEdit={() => handleEdit(tool)}
								onRemove={() => handleDelete(tool)}
							/>
						))}
					</div>
				)}
			</div>

			{modalIsOpen && (
				<AdminToolsCreate
					closeModal={handleCloseModal}
					closeAfterCreate={handleCloseAfterAction}
					editingTool={
						editingTool
							? {
									id: editingTool.id,
									name: editingTool.name,
									description: editingTool.description,
									image: editingTool.image,
									access_link: editingTool.url_access,
									more_link: editingTool.url_video,
								}
							: null
					}
				/>
			)}

			{successModal && (
				<AlertModal
					title={
						editingTool
							? "Alterações salvas com sucesso!"
							: "Ferramenta adicionada com sucesso!"
					}
					icon="done.svg"
					confirmText="Concluir"
					hasCancel={false}
					confirmAction={() => {
						setSuccessModal(false);
						setEditingTool(null);
					}}
					cancelAction={() => {
						setSuccessModal(false);
						setEditingTool(null);
					}}
					closeModal={() => {
						setEditingTool(null);
						setSuccessModal(false);
					}}
				/>
			)}

			{showDeleteLessonModal && (
				<DeleteModal
					title="Deseja realmente excluir essa ferramenta?"
					description="Essa ação não poderá ser desfeita."
					icon="warning.svg"
					confirmText="Excluir ferramenta"
					isLoading={isLoading}
					cancelAction={() => {
						setShowDeleteLessonModal(false);
					}}
					confirmAction={async () => {
						setIsLoading(true);
						const response = await deleteTool(editingTool!.id);

						if (response.status === "success") {
							setIsLoading(false);
							setShowDeleteLessonModal(false);
							setShowDeleteLessonSuccessModal(true);
							await fetchTools();
						}
					}}
					closeModal={() => {
						setShowDeleteLessonModal(false);
					}}
				/>
			)}

			{showDeleteLessonSuccessModal && (
				<AlertModal
					title="Ferramenta excluída com sucesso!"
					icon="done.svg"
					confirmText="Concluir"
					hasCancel={false}
					confirmAction={() => {
						setShowDeleteLessonSuccessModal(false);
					}}
					closeModal={() => {
						setShowDeleteLessonSuccessModal(false);
					}}
				/>
			)}
		</>
	);
}

function AdminToolCardSkeleton() {
	return (
		<div className="flex items-center justify-between gap-4 rounded-[8px] bg-neutral-1100 bg-opacity-[0.03] p-4">
			<div className="flex size-14 items-center justify-center">
				<div className="size-14 animate-pulse rounded-[10px] bg-neutral-200" />
			</div>

			<div className="flex flex-1 space-x-4">
				<div className="flex w-full flex-col gap-2">
					<div className="flex flex-col gap-[2px]">
						<div className="h-4 w-16 animate-pulse rounded bg-neutral-200" />
						<div className="h-5 w-40 animate-pulse rounded bg-neutral-200" />
					</div>
				</div>
			</div>
		</div>
	);
}
