import { AxiosError } from "axios";

import { api } from "@/lib/axios";

export async function deleteBanner(id: string): Promise<
	| {
			status: "success";
	  }
	| { status: "error"; errors: { [key: string]: string } }
> {
	try {
		await api.delete(`/banner/delete/${id}`);

		return {
			status: "success",
		};
	} catch (error: unknown) {
		const axiosError = error as AxiosError<{
			errors: { [key: string]: string };
		}>;

		if (axiosError.response?.status === 422) {
			return {
				status: "error",
				errors: axiosError.response.data.errors,
			};
		}

		return {
			status: "error",
			errors: {
				unknown: "Erro desconhecido. Tente novamente mais tarde.",
			},
		};
	}
}
