import { createBrowserRouter } from "react-router-dom";

import { AppLayout } from "./pages/_layouts/app";
import { AuthLayout } from "./pages/_layouts/auth";
import { StickyAppLayout } from "./pages/_layouts/sticky-app";
import { Admin } from "./pages/admin";
import { AdminAcademy } from "./pages/admin/academy";
import { AdminAcademyShow } from "./pages/admin/academy/[id]";
import { AdminAcademyMaterials } from "./pages/admin/academy/materials";
import { AdminAcademyTrainings } from "./pages/admin/academy/trainings";
import { AdminBanner } from "./pages/admin/banner";
import { AdminHelp } from "./pages/admin/help";
import { AdminPanel } from "./pages/admin/panel";
import { AdminPanelSales } from "./pages/admin/panel/sales";
import { AdminPanelUsers } from "./pages/admin/panel/users";
import { AdminPersonalizationAwards } from "./pages/admin/personalization/awards";
import { AdminProducts } from "./pages/admin/products";
import { AdminProductsShow } from "./pages/admin/products/[id]";
import { AdminProductsShowAbout } from "./pages/admin/products/[id]/about";
import { AdminProductsShowDetails } from "./pages/admin/products/[id]/details";
import { AdminProductsShowMaterials } from "./pages/admin/products/[id]/materials";
import { AdminProductsShowQuestions } from "./pages/admin/products/[id]/questions";
import { AdminTools } from "./pages/admin/tools";
import { AdminUsers } from "./pages/admin/users";
import { Academy } from "./pages/app/academy";
import { AcademyTraining } from "./pages/app/academy/[id]";
import { AcademyFreeMaterials } from "./pages/app/academy/free-materials";
import { AcademyTrainings } from "./pages/app/academy/trainings";
import { Account } from "./pages/app/account";
import { AccountAchievements } from "./pages/app/account/achievements";
import { AccountIntegrations } from "./pages/app/account/integrations";
import { AccountPassword } from "./pages/app/account/password";
import { AccountProfile } from "./pages/app/account/profile";
import { Awards } from "./pages/app/awards";
import { Dashboard } from "./pages/app/dashboard";
import { Help } from "./pages/app/help";
import { Home } from "./pages/app/home";
import { Products } from "./pages/app/products";
import { Product } from "./pages/app/products/[id]";
import { ProductAbout } from "./pages/app/products/[id]/about";
import { ProductMaterials } from "./pages/app/products/[id]/materials";
import { ProductQuestions } from "./pages/app/products/[id]/questions";
import { Tools } from "./pages/app/tools";
import { ForgotPassword } from "./pages/auth/forgot-password";
import { ForgotPasswordChagePassword } from "./pages/auth/forgot-password/change-password";
import { ForgotPasswordConfirm } from "./pages/auth/forgot-password/confirm";
import { ForgotPasswordSendEmail } from "./pages/auth/forgot-password/send-email";
import { Onboarding } from "./pages/auth/onboarding";
import { SignIn } from "./pages/auth/sign-in";
import { SignUp } from "./pages/auth/sign-up";
import { SignUpConfirm } from "./pages/auth/sign-up/confirm";
import { SignUpPassword } from "./pages/auth/sign-up/password";
import { SignUpWelcome } from "./pages/auth/sign-up/welcome";

export const router = createBrowserRouter([
	{
		path: "/",
		element: <AppLayout />,
		children: [
			{ path: "/", element: <Home /> },
			{
				path: "/products",
				element: <Products />,
			},
			{
				path: "/products/:id",
				element: <Product />,
				children: [
					{
						path: "/products/:id",
						element: <ProductAbout />,
					},
					{
						path: "/products/:id/materials",
						element: <ProductMaterials />,
					},
					{
						path: "/products/:id/questions",
						element: <ProductQuestions />,
					},
				],
			},
			{
				path: "/academy",
				element: <Academy />,
			},
			{
				path: "/academy/:trainingId/:lessonId?",
				element: <AcademyTraining />,
			},
			{
				path: "/academy/free-materials",
				element: <AcademyFreeMaterials />,
			},
			{
				path: "/academy/trainings",
				element: <AcademyTrainings />,
			},
			{
				path: "/dashboard",
				element: <Dashboard />,
			},
			{
				path: "/awards",
				element: <Awards />,
			},
			{
				path: "/tools",
				element: <Tools />,
			},
			{
				path: "/help",
				element: <Help />,
			},
		],
	},
	{
		path: "/",
		element: <StickyAppLayout />,
		children: [
			{
				path: "/account",
				element: <Account />,
				children: [
					{
						path: "/account",
						element: <AccountProfile />,
					},
					{
						path: "/account/password",
						element: <AccountPassword />,
					},
					{
						path: "/account/achievements",
						element: <AccountAchievements />,
					},
					{
						path: "/account/integrations",
						element: <AccountIntegrations />,
					},
				],
			},
			{
				path: "/admin",
				element: <Admin />,
				children: [
					{
						path: "",
						element: <AdminPanel />,
						children: [
							{
								path: "",
								element: <AdminPanelSales />,
							},
							{
								path: "panel-users",
								element: <AdminPanelUsers />,
							},
						],
					},
					{
						path: "users",
						element: <AdminUsers />,
					},
					{
						path: "awards",
						element: <AdminPersonalizationAwards />,
					},
					{
						path: "academy",
						element: <AdminAcademy />,
						children: [
							{
								path: "",
								element: <AdminAcademyTrainings />,
							},
							{
								path: "materials",
								element: <AdminAcademyMaterials />,
							},
						],
					},
					{
						path: "academy/:id",
						element: <AdminAcademyShow />,
					},
					{
						path: "products",
						element: <AdminProducts />,
					},
					{
						path: "products/:id",
						element: <AdminProductsShow />,
						children: [
							{
								path: "", // This will match /admin/products/:id directly
								element: <AdminProductsShowDetails />,
							},
							{
								path: "about",
								element: <AdminProductsShowAbout />,
							},
							{
								path: "materials",
								element: <AdminProductsShowMaterials />,
							},
							{
								path: "questions",
								element: <AdminProductsShowQuestions />,
							},
						],
					},
					{
						path: "help",
						element: <AdminHelp />,
					},
					{
						path: "tools",
						element: <AdminTools />,
					},
					{
						path: "banner",
						element: <AdminBanner />,
					},
				],
			},
		],
	},
	{
		path: "/",
		element: <AuthLayout />,
		children: [
			{
				path: "/sign-in",
				element: <SignIn />,
			},
			{
				path: "/forgot-password",
				element: <ForgotPassword />,
			},
			{
				path: "/forgot-password-email",
				element: <ForgotPasswordSendEmail />,
			},
			{
				path: "/forgot-password-change",
				element: <ForgotPasswordChagePassword />,
			},
			{
				path: "/forgot-password-confirm",
				element: <ForgotPasswordConfirm />,
			},
			{
				path: "/sign-up",
				element: <SignUp />,
			},
			{
				path: "/sign-up-confirm",
				element: <SignUpConfirm />,
			},
			{
				path: "/sign-up-password",
				element: <SignUpPassword />,
			},
			{
				path: "/sign-up-welcome",
				element: <SignUpWelcome />,
			},
		],
	},
	{
		path: "/onboarding",
		element: <Onboarding />,
	},
	{
		path: "*",
		element: <div>Not found</div>,
	},
]);
