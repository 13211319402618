import { AxiosError } from "axios";

import { api } from "@/lib/axios";

export type Tool = {
	id: string;
	name: string;
	description: string;
	image: string;
	url_access: string;
	url_video: string;
};

export async function fetchAdminTools(): Promise<
	| {
			status: "success";
			data: Tool[];
	  }
	| { status: "error"; errors: { [key: string]: string } }
> {
	try {
		const { data } = await api.get("/tool");

		return {
			status: "success",
			data: data.data,
		};
	} catch (error: unknown) {
		const axiosError = error as AxiosError<{
			errors: { [key: string]: string };
		}>;

		if (axiosError.response?.status === 422) {
			return {
				status: "error",
				errors: axiosError.response.data.errors,
			};
		}

		return {
			status: "error",
			errors: {
				unknown: "Erro desconhecido. Tente novamente mais tarde.",
			},
		};
	}
}
