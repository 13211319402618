import { useState } from "react";

import { Button } from "./ui/button";
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogTitle,
} from "./ui/dialog";

interface ToolCardProps {
	name: string;
	description: string;
	image: string;
	access_url: string;
	more_url: string;
}

export function ToolCard({
	name,
	description,
	image,
	access_url,
	more_url,
}: ToolCardProps) {
	const [showVideoModal, setShowVideoModal] = useState(false);

	function handleAccess() {
		window.open(access_url, "_blank");
	}

	function handleMore() {
		setShowVideoModal(true);
	}

	function getUuid(link: string) {
		const url = new URL(link);
		const uuid = url.pathname.split("/")[1];

		return uuid;
	}

	return (
		<>
			<div className="flex items-start justify-start gap-6 rounded-xl bg-neutral-100 p-6">
				<div className="flex size-14 items-center justify-center">
					<img
						src={image}
						alt={name}
						className="size-14 min-w-14 max-w-14 rounded-[10px]"
					/>
				</div>

				<div className="flex w-full flex-col md:flex-row md:gap-6">
					<div className="flex w-full flex-col gap-2">
						<h4 className="font-inter text-P4 font-medium leading-160 text-neutral-1100">
							{name}
						</h4>

						<p className="line-clamp-3 h-[4.8em] font-inter text-P5 font-normal leading-160 text-neutral-600">
							{description}
						</p>
					</div>

					<div className="flex w-full flex-row-reverse justify-center gap-3 md:max-w-[135px] md:flex-col">
						<Button
							type="button"
							variant="primary"
							className="!h-10 w-full font-inter !text-P6 font-semibold leading-160 text-neutral-1100"
							onClick={handleAccess}
						>
							Acessar
						</Button>
						<Button
							type="button"
							variant="secondary"
							className="!h-10 w-full font-inter !text-P6 font-semibold leading-160 text-neutral-1100"
							onClick={handleMore}
						>
							Saiba mais
						</Button>
					</div>
				</div>
			</div>

			<Dialog open={showVideoModal} onOpenChange={setShowVideoModal}>
				<DialogContent className="dark p-0 sm:max-w-[800px]">
					<DialogTitle></DialogTitle>
					<DialogDescription></DialogDescription>
					<div className="aspect-video w-full">
						<iframe
							id={`panda-${getUuid(more_url)}`}
							src={more_url}
							className="absolute left-0 top-0 h-full w-full"
							allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture"
							allowFullScreen={true}
						/>
					</div>
				</DialogContent>
			</Dialog>
		</>
	);
}
