import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

import { type Banner, fetchAdminBanners } from "@/api/admin/banners/list";
import { getLastSales, type LastSales } from "@/api/app/account/latest-sales";
import BoxIcon from "@/assets/box-2.svg?react";
import ChevronRightIcon from "@/assets/chevron-right.svg?react";
import PlayIcon from "@/assets/play.svg?react";
import { Card } from "@/components/card";
import { OnboardingModal } from "@/components/onboarding-modal";
import { Button } from "@/components/ui/button";
import {
	Carousel,
	CarouselApi,
	CarouselContent,
	CarouselItem,
	CarouselNext,
	CarouselPrevious,
} from "@/components/ui/carousel";
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogTitle,
} from "@/components/ui/dialog";
import { Skeleton } from "@/components/ui/skeleton";
import { useNavigationStore } from "@/store/navigation-store";
import { getLocalStorageItem } from "@/utils/local-storage";

export function Home() {
	const [api, setApi] = useState<CarouselApi>();
	const [current, setCurrent] = useState(0);
	const [showVideoModal, setShowVideoModal] = useState(false);

	useEffect(() => {
		if (!api) {
			return;
		}

		setCurrent(api.selectedScrollSnap() + 1);

		api.on("select", () => {
			setCurrent(api.selectedScrollSnap() + 1);
		});
	}, [api]);

	const [setPaths] = useNavigationStore((state) => [state.setPaths]);
	const [showOnboardingModal, setShowOnboardingModal] = useState(false);
	const [finishedOnboarding, setFinishedOnboarding] = useState(false);
	const [loadingBanners, setLoadingBanners] = useState(true);
	const [banners, setBanners] = useState<Banner[]>([]);
	const [videoUrl, setVideoUrl] = useState<string | null>(null);

	const navigate = useNavigate();

	useEffect(() => {
		setPaths([
			{
				name: "Home",
				path: "/",
			},
		]);

		if (!getLocalStorageItem("@fm-suplementos/onboarding")) {
			setShowOnboardingModal(true);
		}
	}, []);

	useEffect(() => {
		fetchAdminBanners().then((response) => {
			if (response.status === "success") {
				setBanners(response.data);
			} else {
				console.error("Erro ao carregar banners", response.errors);
			}
			setLoadingBanners(false);
		});
	}, []);

	const handleBannerClick = (banner: Banner) => {
		if (banner.button_url.includes("pandavideo")) {
			setVideoUrl(banner.button_url);
			setShowVideoModal(true);

			return;
		}

		if (banner.button_url.startsWith("http")) {
			window.open(banner.button_url, "_blank");
		} else {
			navigate(banner.button_url);
		}
	};

	const [lastSales, setLastSales] = useState<null | LastSales>(null);

	useEffect(() => {
		getLastSales().then((response) => {
			if (response.status === "success") {
				setLastSales(response.data);
			}
		});
	}, []);

	function getUuid(link: string) {
		const url = new URL(link);
		const uuid = url.pathname.split("/")[1];

		return uuid;
	}

	return (
		<>
			<Helmet title="Home" />

			<div className="grid grid-cols-1 gap-12 lg:grid-cols-[1fr,352px] lg:gap-8">
				<main className="flex flex-col justify-between gap-12 lg:gap-16">
					{loadingBanners ? (
						<Skeleton className="h-[420px] w-full" />
					) : (
						<Carousel setApi={setApi}>
							<CarouselContent>
								{banners
									.filter((banner) => banner.actived)
									.map((banner) => (
										<CarouselItem key={banner.id}>
											<div
												className="flex h-full min-h-[420px] rounded-xl bg-opacity-40 px-4 pt-[122px] md:pt-[162px] lg:px-6 lg:pt-[80px]"
												style={{
													background: `linear-gradient(180deg, rgba(11, 18, 18, 0.10) 13.77%, #0B1212 81.12%), url(${banner.image}) lightgray 50% / cover no-repeat`,
												}}
											>
												<div className="flex h-full max-h-[210px] w-full flex-col justify-between gap-8 lg:max-w-[688px] lg:gap-12">
													<div className="flex w-full flex-col gap-4 lg:max-w-[688px]">
														<h6 className="font-poppins text-H7 font-semibold text-neutral-1100 lg:text-H6">
															{banner.title}
														</h6>
														<p className="font-inter text-P5 text-neutral-500 lg:text-P4">
															{banner.sub_title}
														</p>
													</div>
													<button
														className={`
														flex h-[48px] w-full items-center justify-center gap-2 rounded-[8px] px-12 font-inter text-P5 font-semibold leading-160 text-neutral-1100 transition-all duration-300 lg:w-[232px]
														${banner.button_color ? `bg-[${banner.button_color}]` : "bg-primary-600 hover:bg-primary-700"}
													`}
														onClick={() => handleBannerClick(banner)}
													>
														{banner.button_text}
													</button>
												</div>
											</div>
										</CarouselItem>
									))}
							</CarouselContent>

							<CarouselPrevious />
							<CarouselNext />

							<div className="absolute bottom-5 left-6 flex gap-4">
								{banners
									.filter((banner) => banner.actived)
									.map((_, index) => {
										return (
											<span
												key={index}
												className={`inline-block h-[2px] w-5 rounded-[10px] bg-neutral-200 transition-all duration-500 ${
													current === index + 1 && "!w-10 !bg-neutral-1100"
												}`}
											></span>
										);
									})}
							</div>
						</Carousel>
					)}

					<section className="flex flex-col gap-2.5 lg:flex-row lg:gap-8">
						<Card
							title="Produtos"
							description="Conheça nossos produtos e comece vender ainda hoje!"
							to="products"
						>
							<BoxIcon />
						</Card>
						<Card
							title="Academy"
							description="Conheça nossos treinamentos e materiais exclusivos!"
							to="academy"
						>
							<PlayIcon />
						</Card>
					</section>
				</main>

				<aside className="flex flex-col gap-[50px] rounded-xl bg-neutral-100 bg-opacity-40 p-6">
					<section className="flex flex-col gap-6">
						<p className="font-inter text-P3 font-medium leading-160 text-neutral-1100">
							Primeiros passos
						</p>

						<div className="flex flex-col gap-4">
							<button
								className="flex w-full flex-col gap-2 rounded-[10px] bg-neutral-100 bg-opacity-60 px-6 py-4 text-neutral-500 transition-all duration-300 hover:bg-neutral-100 hover:text-neutral-800"
								onClick={() => {
									setVideoUrl(null);
									setShowVideoModal(true);
								}}
							>
								<div className="flex w-full items-center justify-between">
									<p className="font-inter text-P4 font-medium leading-160 text-neutral-1100">
										Inicie sua jornada
									</p>
									<ChevronRightIcon />
								</div>

								<p className="text-left font-inter text-P5 font-normal leading-160">
									Indicado para quem está começando
								</p>
							</button>
							<button
								className="flex w-full flex-col gap-2 rounded-[10px] bg-neutral-100 bg-opacity-60 px-6 py-4 text-neutral-500 transition-all duration-300 hover:bg-neutral-100 hover:text-neutral-800"
								onClick={() => {
									navigate("/products");
								}}
							>
								<div className="flex w-full items-center justify-between">
									<p className="font-inter text-P4 font-medium leading-160 text-neutral-1100">
										Filie-se a um produto
									</p>
									<ChevronRightIcon />
								</div>

								<p className="text-left font-inter text-P5 font-normal leading-160">
									Conheça nossos produtos e venda
								</p>
							</button>
						</div>
					</section>

					{lastSales && (
						<section className="flex flex-col gap-6">
							<div className="flex justify-between">
								<p className="font-inter text-P3 font-medium leading-160 text-neutral-1100">
									Vendas e comissões
								</p>
							</div>

							<div className="flex flex-col gap-4 rounded-[10px] border border-neutral-100 p-4">
								<div className="flex w-full flex-col gap-2 rounded-[10px] bg-neutral-100 bg-opacity-60 px-6 py-4 text-neutral-500 transition-all duration-300">
									<p className="font-inter text-P6 font-normal leading-160 text-neutral-600">
										Total de vendas
									</p>

									<p className="font-inter text-P3 font-bold leading-160 text-neutral-1100">
										{lastSales?.count_sales}
									</p>
								</div>

								<div className="flex w-full flex-col gap-2 rounded-[10px] bg-neutral-100 bg-opacity-60 px-6 py-4 text-neutral-500 transition-all duration-300">
									<p className="font-inter text-P6 font-normal leading-160 text-neutral-600">
										Total de comissões
									</p>

									<p className="font-inter text-P3 font-bold leading-160 text-neutral-1100">
										{lastSales?.total_commission}
									</p>
								</div>

								<Button
									onClick={() => {
										navigate("/dashboard");
									}}
									className="flex !h-8 w-full items-center justify-center gap-2 rounded-[4px] px-4 font-inter !text-P7 font-semibold leading-160 text-neutral-1100"
									type="button"
									variant="secondary"
								>
									Ver mais
								</Button>
							</div>
						</section>
					)}

					{!lastSales && (
						<section className="flex flex-col gap-6">
							<div className="flex flex-col gap-4 rounded-[10px] border border-neutral-100 p-4">
								<div className="flex w-full flex-col gap-2 rounded-[10px] bg-neutral-100 bg-opacity-60 px-6 py-4">
									<Skeleton className="h-4 w-24" />
									<Skeleton className="h-6 w-32" />
								</div>
								<div className="flex w-full flex-col gap-2 rounded-[10px] bg-neutral-100 bg-opacity-60 px-6 py-4">
									<Skeleton className="h-4 w-24" />
									<Skeleton className="h-6 w-32" />
								</div>
								<Skeleton className="h-8 w-full rounded-[4px]" />
							</div>
						</section>
					)}
				</aside>

				{showOnboardingModal && !finishedOnboarding && (
					<div className="fixed bottom-0 left-0 right-0 top-0 z-[20] bg-neutral-0 bg-opacity-60">
						<div className="absolute bottom-0 left-0 right-0 top-0 lg:bottom-auto lg:left-1/2 lg:right-auto lg:top-1/2 lg:-translate-x-1/2 lg:-translate-y-1/2 lg:transform">
							<OnboardingModal
								setFinishedOnboarding={() => {
									setFinishedOnboarding(true);
									setShowOnboardingModal(false);
								}}
							/>
						</div>
					</div>
				)}
			</div>

			<Dialog open={showVideoModal} onOpenChange={setShowVideoModal}>
				<DialogContent className="dark p-0 sm:max-w-[800px]">
					<DialogTitle></DialogTitle>
					<DialogDescription></DialogDescription>
					<div className="aspect-video w-full">
						<iframe
							id={`panda-${getUuid(videoUrl ?? "https://player-vz-4a4c46da-f7f.tv.pandavideo.com.br/embed/?v=8b3e18c4-1ff8-4ca0-bfd9-25352bafd839")}`}
							src={
								videoUrl ??
								"https://player-vz-4a4c46da-f7f.tv.pandavideo.com.br/embed/?v=8b3e18c4-1ff8-4ca0-bfd9-25352bafd839"
							}
							className="absolute left-0 top-0 h-full w-full"
							allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture"
							allowFullScreen={true}
						/>
					</div>
				</DialogContent>
			</Dialog>
		</>
	);
}
